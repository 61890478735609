import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [3,4,5,8];

export const dictionary = {
		"/": [~9],
		"/app/(guarded)": [10,[,3],[2]],
		"/app/(guarded)/admin": [11,[,3,4],[2]],
		"/app/(guarded)/admin/comparable-search": [12,[,3,4],[2]],
		"/app/(guarded)/admin/comparable-search/[searchId]": [~13,[,3,4],[2]],
		"/app/(guarded)/admin/dashboard": [~14,[,3,4],[2]],
		"/app/(guarded)/admin/users": [15,[,3,4],[2]],
		"/app/(guarded)/diag/experiments/rating-swiper": [16,[,3,5],[2]],
		"/app/(guarded)/diag/mls/[mlsId]": [~17,[,3,5],[2]],
		"/app/(guarded)/intro": [18,[,3],[2]],
		"/app/login": [~25,[,6],[2]],
		"/app/login/magic": [~26,[,6],[2]],
		"/app/login/validate": [~27,[,6],[2]],
		"/app/(guarded)/pipeline": [19,[,3],[2]],
		"/app/(guarded)/pipeline/new": [21,[,3],[2]],
		"/app/(guarded)/pipeline/[reportId]": [20,[,3],[2]],
		"/app/(guarded)/profile": [22,[,3],[2]],
		"/app/(guarded)/support": [23,[,3],[2]],
		"/app/(guarded)/tutorial": [24,[,3],[2]],
		"/client-report/[key]": [~28,[7]],
		"/internal/clientReportPrintAgent": [~29],
		"/test": [~30,[8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';