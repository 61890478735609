// hooks.client.(js|ts)
import { dev } from '$app/environment';
import { PUBLIC_SENTRY_DSN } from '$env/static/public';
import * as Sentry from '@sentry/sveltekit';
if (import.meta.env.PROD) {
    Sentry.init({
        dsn: PUBLIC_SENTRY_DSN,
        tracesSampleRate: 1.0,
        // For instance, initialize Session Replay:
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [Sentry.replayIntegration()]
    });
}

export const handleError = import.meta.env.PROD?Sentry.handleErrorWithSentry():undefined;
